@import '../../../../colors.css';

.title-line {
  margin-top: -18px;
  display: flex;
}

.entry-container--large-font .title-line {
  margin-top: -24px;
}

.todo-keyword {
  font-weight: bold;
  margin-right: 5px;
  color: var(--orange);
}

.todo-keyword--done-state {
  color: green;
}

.todo-keyword--none {
  color: var(--base01);
}

.header-tag {
  border: 1px solid var(--base01);
  padding: 1px 2px;
  margin: 2px;
  display: inline-block;
  border-radius: 5px;
}

.title-line__edit-container {
  width: 100%;
}

.title-line__insert-timestamp-button {
  font-family: sans-serif;
}

.title-line__insert-timestamp-button {
  color: gray;

  font-family: Courier;

  display: flex;
  align-items: center;
}

.insert-timestamp-icon {
  margin-right: 5px;
}
