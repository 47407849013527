.planning-items__item-container {
  display: flex;
}

.planning-item__type {
  color: gray;
}

.planning-item__timestamp {
  cursor: pointer;
  color: blue;
  text-decoration: underline;
}
