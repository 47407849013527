@import '../../colors.css';

.file-browser-container a {
  text-decoration: none;
  color: black;
}

.file-browser__header {
  margin: 5px;
}

.file-browser__file-list {
  list-style-type: none;
  padding-left: 0;
}

.file-browser__file-list__icon {
  color: var(--base02);
}

.file-browser__file-list__element {
  border-top: 1px solid #d8d8d8;
  padding: 10px;
  font-family: Courier;
}

.file-browser__file-list__element:last-of-type {
  border-bottom: 1px solid #d8d8d8;
}

.file-browser__file-list__element--load-more-row:last-of-type {
  border-bottom: none;
  text-align: center;
  color: var(--base2);
  font-weight: bold;
}

.file-browser__file-list__loading-more-container {
  display: flex;
  justify-content: center;

  color: var(--base2);

  margin-top: 10px;
}

.file-browser__file-list__icon--not-org {
  color: var(--base01);
}

.file-browser__file-list__icon--directory {
  color: var(--base00);
}
