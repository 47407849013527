.App {
  height: 100%;
}

.entry-container {
  font-size: 16px;

  display: flex;
  flex-direction: column;
  height: 100%;

  overflow-x: hidden;
}

.entry-container--large-font {
  font-size: 20px;
}
