@import '../../colors.css';

footer {
  position: relative;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  margin-top: 1rem;
  background-color: var(--base2);
  text-align: center;
}

footer a {
  color: var(--base00);
}

.landing-container-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.landing-container a {
  text-decoration: none;
}

.landing-button {
  text-align: center;

  font-size: 20px;
  margin-top: 30px;
  width: 200px;
}

.landing-app-name {
  font-size: 80px;
  font-weight: 400;
  color: var(--base01);

  margin-top: 0;
  margin-bottom: 20px;
}

.landing-tagline {
  font-size: 24px;
  font-weight: 300;

  margin-top: 0;
  margin-bottom: 0.5em;

  text-align: center;
}

.landing-logo {
  height: 7em;
}

.view-sample-button {
  margin-top: 40px;
}

.mailing-list-signup-container {
  margin-top: 40px;
  font-size: 20px;
  max-width: 280px;
  text-align: center;
}
