@import '../../../../colors.css';

.no-tags-message {
  color: gray;
  text-align: center;
}

.tag-container {
  display: flex;

  padding: 3px 5px;
}

.tag-container--dragging {
  background-color: #e6e6e6;
}

.tag-container__drag-handle {
  user-select: none;
}

.tag-container__textfield {
  flex: 3;
}

.tag-container__actions-container {
  flex: 1;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding-left: 10px;

  color: var(--base2);
}

.tags-editor__add-new-container {
  display: flex;
  justify-content: flex-end;

  margin-top: 10px;
}

.tags-editor__separator {
  width: calc(100% - 20px);
  margin-top: 20px;
  margin-bottom: 20px;

  border: none;
  border-bottom: 1px solid #d8d8d8;
}

.all-tags-container {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 15px;
}

.all-tags__tag {
  cursor: pointer;
  color: var(--base00);
  background-color: var(--base2);

  padding: 8px;
  margin: 5px;
}

.all-tags__tag:hover {
  color: var(--base2);
  background-color: var(--base00);
}

.all-tags__tag--in-use {
  background-color: var(--base01);
  color: var(--base2);
}
