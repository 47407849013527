@import '../../../colors.css';

.drawer-outer-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 3;
}

.drawer-outer-container--visible {
  background-color: rgba(238, 232, 213, 0.1);
}

.drawer-inner-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: -1px;
  left: -1px;
  right: -1px;

  padding: 15px 10px;

  box-shadow: 0px -1px 35px 0px var(--base03);
  border-bottom: none;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;

  background-color: var(--base3);
  max-height: 97%;

  overflow: auto;
}

.drawer__close-button {
  position: absolute;
  top: 15px;
  right: 15px;

  color: var(--base2);

  border: 0;
  background-color: transparent;
  padding: 0;
}

.drawer__grabber {
  width: 35px;
  height: 5px;
  background-color: gray;
  flex-shrink: 0;

  border-radius: 50px;

  margin-left: auto;
  margin-right: auto;

  margin-top: -5px;
  margin-bottom: 5px;
}

.drawer-modal__title {
  color: var(--base2);

  margin-top: 0;
}
